@media (min-width: 768px) {
  .container {
    width: 768px;
  }
}

@media (min-width: 900px) {
  .container {
    width: 900px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1400px;
  }
}

@media (max-width: 767px) {
  .container {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
  h1, .h1 {
    font-size: 42px;
    line-height: 50px;
  }
  h2, .h2 {
    font-size: 36px;
    line-height: 44px;
  }
  h3, .h3 {
    font-size: 30px;
    line-height: 38px;
  }
  h4, .h4 {
    font-size: 24px;
    line-height: 32px;
  }
  h5, .h5 {
    font-size: 18px;
    line-height: 26px;
  }
  h6, .h6 {
    font-size: 12px;
    line-height: 20px;
  }
}

@media (max-width: 1199px) {
  .alert-container > .placement {
    width: 100%;
    height: 100%;
  }
  .alert-container > .placement > .alert-id {
    min-width: 0;
  }
  .alert-container > .placement[data-alert-placement^="bottom-"],
  .alert-container > .placement[data-alert-placement^="center-"],
  .alert-container > .placement[data-alert-placement$="-right"],
  .alert-container > .placement[data-alert-placement$="-center"] {
    left: 0;
    top: 0;
    bottom: auto;
    right: auto;
  }
}

@media (max-width: 479px) {
  .alert-container > .placement > .alert-id {
    width: 100%;
  }
  .alert-container > .placement > .alert-id > .alert-wrapper > .alert-footer {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 100%;
  }
}

@media (max-width: 767px) {
  .btn {
    display: block;
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .confirm-container > .placement {
    width: 100%;
    height: 100%;
  }
  .confirm-container > .placement > .confirm-id {
    min-width: 0;
  }
  .confirm-container > .placement[data-confirm-placement^="bottom-"],
  .confirm-container > .placement[data-confirm-placement^="center-"],
  .confirm-container > .placement[data-confirm-placement$="-right"],
  .confirm-container > .placement[data-confirm-placement$="-center"] {
    left: 0;
    top: 0;
    bottom: auto;
    right: auto;
  }
}

@media (max-width: 479px) {
  .confirm-container > .placement > .confirm-id {
    width: 100%;
  }
  .confirm-container > .placement > .confirm-id > .confirm-wrapper > .confirm-footer {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 100%;
  }
}

@media (max-width: 479px) {
  .datepicker > .datepicker-wrapper > .datepicker-footer {
    grid-template-columns: 2fr 2fr;
  }
}

@media (max-width: 319px) {
  .datepicker > .datepicker-wrapper > .datepicker-footer {
    grid-template-columns: 100%;
  }
}

@media (max-width: 899px) {
  .row {
    display: block;
    width: 100%;
  }
  .row > .col-1 {
    display: block;
    width: 100%;
  }
  .row > .col-2 {
    display: block;
    width: 100%;
  }
  .row > .col-3 {
    display: block;
    width: 100%;
  }
  .row > .col-4 {
    display: block;
    width: 100%;
  }
  .row > .col-5 {
    display: block;
    width: 100%;
  }
  .row > .col-6 {
    display: block;
    width: 100%;
  }
  .row > .col-7 {
    display: block;
    width: 100%;
  }
  .row > .col-8 {
    display: block;
    width: 100%;
  }
  .row > .col-9 {
    display: block;
    width: 100%;
  }
  .row > .col-10 {
    display: block;
    width: 100%;
  }
  .row > .col-11 {
    display: block;
    width: 100%;
  }
  .row > .col-12 {
    display: block;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .columns-1 {
    grid-template-columns: 100%;
  }
  .columns-2 {
    grid-template-columns: 100%;
  }
  .columns-3 {
    grid-template-columns: 100%;
  }
  .columns-4 {
    grid-template-columns: 100%;
  }
  .columns-5 {
    grid-template-columns: 100%;
  }
  .columns-6 {
    grid-template-columns: 100%;
  }
  .columns-7 {
    grid-template-columns: 100%;
  }
  .columns-8 {
    grid-template-columns: 100%;
  }
  .columns-9 {
    grid-template-columns: 100%;
  }
  .columns-10 {
    grid-template-columns: 100%;
  }
  .columns-11 {
    grid-template-columns: 100%;
  }
  .columns-12 {
    grid-template-columns: 100%;
  }
  .column-1 {
    grid-column: unset;
  }
  .column-1-2 {
    grid-column: unset;
  }
  .column-1-3 {
    grid-column: unset;
  }
  .column-1-4 {
    grid-column: unset;
  }
  .column-1-5 {
    grid-column: unset;
  }
  .column-1-6 {
    grid-column: unset;
  }
  .column-1-7 {
    grid-column: unset;
  }
  .column-1-8 {
    grid-column: unset;
  }
  .column-1-9 {
    grid-column: unset;
  }
  .column-1-10 {
    grid-column: unset;
  }
  .column-1-11 {
    grid-column: unset;
  }
  .column-1-12 {
    grid-column: unset;
  }
}

@media (max-width: 767px) {
  .modal {
    padding: 12px;
  }
}

@media (max-width: 899px) {
  .navbar .navbar-container {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding-top: 12px;
    padding-bottom: 12px;
    gap: 12px;
  }
  .navbar .navbar-container:not(.container) {
    padding-left: 12px;
    padding-right: 12px;
  }
  /* Nav wrapper + */
  .navbar .navbar-container > .navbar-wrapper {
    display: none;
    width: 100%;
  }
  .navbar.navbar-active .navbar-container > .navbar-wrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 12px;
  }
  .navbar .navbar-container > .navbar-wrapper > .nav-left,
  .navbar .navbar-container > .navbar-wrapper > .nav-center,
  .navbar .navbar-container > .navbar-wrapper > .nav-right {
    display: block;
    margin: 0;
  }
  .navbar .navbar-container > .navbar-wrapper .navbar-menu {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 100%;
  }
  /* Nav wrapper - */
  /* Nav menu items + */
  .navbar .navbar-container > .navbar-wrapper .navbar-menu > .nav-item {
    display: grid;
    grid-template-columns: 100%;
  }
  .navbar .navbar-container > .navbar-wrapper .navbar-menu > .nav-item > .nav-link {
    padding: 16px;
    width: 100%;
    text-align: center;
    -ms-flex-pack: center;
        justify-content: center;
  }
  .navbar .navbar-container > .navbar-wrapper .navbar-menu > .nav-item > .nav-link:after {
    display: none;
  }
  .navbar .navbar-container > .navbar-wrapper .navbar-menu > .nav-item.active > .nav-link,
  .navbar .navbar-container > .navbar-wrapper .navbar-menu > .nav-item:active > .nav-link,
  .navbar .navbar-container > .navbar-wrapper .navbar-menu > .nav-item:hover > .nav-link {
    background-color: rgba(0, 0, 0, 0.04);
  }
  /* Nav menu items - */
  /* Nav item brand + */
  .navbar .navbar-container > .nav-brand {
    font-size: 18px;
  }
  /* Nav item brand - */
  /* Nav collapse trigger + */
  .navbar .navbar-container > .nav-mobile {
    display: -ms-flexbox;
    display: flex;
  }
  /* Nav collapse trigger - */
  .navbar .navbar-wrapper .navbar-menu > li.dropdown .dropdown-list {
    position: static;
    width: 100%;
    box-shadow: none;
    margin-top: 12px;
    padding-bottom: 0;
  }
  /*******************************************
  *
  * Text light theme
  *
  *******************************************/
  .navbar.navbar-text-light .navbar-container > .nav-mobile {
    color: #757575;
  }
  .navbar.navbar-text-light .navbar-container > .nav-mobile .nav-mobile-bars {
    background-color: #757575;
  }
  .navbar.navbar-text-light .navbar-container > .nav-mobile .nav-mobile-bars:before,
  .navbar.navbar-text-light .navbar-container > .nav-mobile .nav-mobile-bars:after {
    background-color: #757575;
  }
  .navbar.navbar-text-light.navbar-active .navbar-container > .nav-mobile,
  .navbar.navbar-text-light .navbar-container > .nav-mobile:hover,
  .navbar.navbar-text-light .navbar-container > .nav-mobile:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar.navbar-text-light .navbar-container > .navbar-wrapper .navbar-menu > .nav-item.active > .nav-link,
  .navbar.navbar-text-light .navbar-container > .navbar-wrapper .navbar-menu > .nav-item:active > .nav-link,
  .navbar.navbar-text-light .navbar-container > .navbar-wrapper .navbar-menu > .nav-item:hover > .nav-link {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 899px) {
  .nav-menu .nav-menu-link,
  .nav-menu .nav-menu-link .link-icon {
    padding: 16px;
  }
}

@media (max-width: 319px) {
  .nav-menu .nav-menu-link,
  .nav-menu .nav-menu-link .link-icon {
    padding: 12px;
  }
}

@media (max-width: 1199px) {
  .table.table-responsive,
  .table.table-responsive .table-row,
  .table.table-responsive .table-body,
  .table.table-responsive .table-footer,
  .table.table-responsive .table-column,
  .table.table-responsive .table-col-group,
  .table.table-responsive .table-cell,
  .table.table-responsive .table-caption,
  .table.table-responsive .table-cell-resp {
    display: block;
  }
  .table.table-responsive .table-cell {
    text-align: left;
  }
  .table.table-responsive .table-header {
    display: none;
  }
  .table.table-responsive .table-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    -ms-flex-align: start;
        align-items: start;
    border-top: 1px solid #eee;
    margin-bottom: 20px;
  }
  .table.table-responsive .table-row:last-child {
    border-bottom: 1px solid #eee;
  }
  .table.table-responsive .table-row:last-child {
    margin: 0;
  }
  .table.table-responsive .table-row > .table-cell:last-child {
    text-align: left;
  }
}

@media (max-width: 1399px) {
  .slider {
    height: 307px;
  }
}

@media (max-width: 1199px) {
  .slider {
    height: 227px;
  }
}

@media (max-width: 899px) {
  .slider {
    height: 192px;
  }
}

@media (max-width: 479px) {
  .slider {
    height: 116px;
  }
}

@media (max-width: 319px) {
  .slider {
    height: 74px;
  }
  .slider:before,
  .slider:after {
    top: 8px;
    right: 8px;
  }
  .slider:before {
    margin-right: 8px;
  }
  .slider > .slider-arrow {
    top: calc(50% - 16px);
    width: 32px;
    height: 32px;
    font-size: 20px;
  }
  .slider > .slider-arrow[data-slider-arrow="prev"] {
    left: 8px;
  }
  .slider > .slider-arrow[data-slider-arrow="next"] {
    right: 8px;
  }
  .slider > .slider-labels {
    bottom: 8px;
  }
}

@media (max-width: 1199px) {
  .bbpanel > .bbcode-list > .bbcode-separator {
    display: none;
  }
}

@media (max-width: 899px) {
  .bbpanel > .bbcode-list.focus {
    height: auto;
  }
}

@media (max-width: 767px) {
  .tabs > .tab-links {
    grid-template-columns: 100%;
  }
  .tabs.tabs-horizontal {
    grid-template-columns: 100%;
  }
  .tabs > .tab-links,
  .tabs.tabs-horizontal > .tab-links {
    border-radius: 2px 2px 0 0;
    padding: 12px;
    display: -ms-flexbox;
    display: flex;
    gap: 8px;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .tabs > .tab-links > li,
  .tabs.tabs-horizontal > .tab-links > li {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    width: auto;
  }
  .tabs > .tab-links > li .tab-link,
  .tabs.tabs-horizontal > .tab-links > li .tab-link {
    min-height: 48px;
    min-width: 48px;
    border: none;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 8px 28px;
  }
  .tabs > .tab-links > li .tab-link:hover,
  .tabs.tabs-horizontal > .tab-links > li .tab-link:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .tabs > .tab-links > li .tab-link.active,
  .tabs.tabs-horizontal > .tab-links > li .tab-link.active {
    background-color: rgba(0, 0, 0, 0.4);
    border: none;
  }
  .tabs > .tab-links > li .tab-link:after,
  .tabs.tabs-horizontal > .tab-links > li .tab-link:after {
    display: none;
  }
}

@media (max-width: 1399px) {
  .gallery > .gallery-wrapper > .gallery-preview {
    height: 652px;
  }
  .gallery > .gallery-wrapper > .gallery-preview > .gallery-preview-wrapper > img {
    max-height: 652px;
  }
}

@media (max-width: 1199px) {
  .gallery > .gallery-wrapper > .gallery-preview {
    height: 483px;
  }
  .gallery > .gallery-wrapper > .gallery-preview > .gallery-preview-wrapper > img {
    max-height: 483px;
  }
}

@media (max-width: 899px) {
  .gallery > .gallery-wrapper > .gallery-preview {
    height: 409px;
  }
  .gallery > .gallery-wrapper > .gallery-preview > .gallery-preview-wrapper > img {
    max-height: 409px;
  }
  .gallery > .gallery-wrapper > .gallery-list > .gallery-item {
    width: 160px;
  }
  .gallery > .gallery-wrapper > .gallery-list > .gallery-item > .gallery-item-image {
    height: 90px;
  }
}

@media (max-width: 767px) {
  .gallery > .gallery-wrapper > .gallery-preview {
    height: 360px;
  }
  .gallery > .gallery-wrapper > .gallery-preview > .gallery-preview-wrapper > img {
    max-height: 360px;
  }
}

@media (max-width: 479px) {
  .gallery > .gallery-wrapper > .gallery-preview {
    height: 280px;
  }
  .gallery > .gallery-wrapper > .gallery-preview > .gallery-preview-wrapper > img {
    max-height: 280px;
  }
}

@media (max-width: 319px) {
  .gallery > .gallery-wrapper > .gallery-preview {
    height: 160px;
  }
  .gallery > .gallery-wrapper > .gallery-preview > .gallery-preview-wrapper > img {
    max-height: 160px;
  }
}

@media (min-width: 320px) {
  .breakpoint-phone-from {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-phone-from-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (min-width: 480px) {
  .breakpoint-smartphone-from {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-smartphone-from-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (min-width: 768px) {
  .breakpoint-tablet-from {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-tablet-from-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (min-width: 900px) {
  .breakpoint-tablet-fullscreen-from {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-tablet-fullscreen-from-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (min-width: 1200px) {
  .breakpoint-desktop-from {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-desktop-from-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (min-width: 1400px) {
  .breakpoint-desktop-fullscreen-from {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-desktop-fullscreen-from-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (width: 320px) {
  .breakpoint-phone {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-phone-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (width: 480px) {
  .breakpoint-smartphone {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-smartphone-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (width: 768px) {
  .breakpoint-tablet {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-tablet-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (width: 900px) {
  .breakpoint-tablet-fullscreen {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-tablet-fullscreen-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (width: 1200px) {
  .breakpoint-desktop {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-desktop-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (width: 1400px) {
  .breakpoint-desktop-fullscreen {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-desktop-fullscreen-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (max-width: 1399px) {
  .breakpoint-desktop-fullscreen-to {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-desktop-fullscreen-to-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (max-width: 1199px) {
  .breakpoint-desktop-to {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-desktop-to-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (max-width: 899px) {
  .breakpoint-tablet-fullscreen-to {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-tablet-fullscreen-to-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (max-width: 767px) {
  .breakpoint-tablet-to {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-tablet-to-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (max-width: 479px) {
  .breakpoint-smartphone-to {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-smartphone-to-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (max-width: 319px) {
  .breakpoint-phone-to {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-phone-to-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}
